import { useState } from 'react';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Price.css';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import WhatsApp from '../../../WhatsUp/WhatsappLink';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>EN</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang ? <Link to="/price">PL</Link> : <Link to="/price">RU</Link>}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

export function PriceTable() {
  return (
    <>
      <div className="price">
        <div className="price-title">
          <h1>Price</h1>
        </div>
        <div className="outsidewidthtable">
          <table className="table">
            <tbody>
              <tr>
                <th className="thleft">Service name</th>
                <th>Cost from (pln)</th>
              </tr>
              <tr>
                <td colSpan="2" className="priceOne">
                  PRICE LIST FOR FURNITURE UPHOLSTERY WASHING:
                </td>
              </tr>
              <tr>
                <td>washing the couch</td>
                <td className="center"> 150</td>
              </tr>
              <tr>
                <td>washing and cleaning car upholstery</td>
                <td className="center">250</td>
              </tr>
              <tr>
                <td>washing sofa</td>
                <td className="center"> 150</td>
              </tr>
              <tr>
                <td>corner washing </td>
                <td className="center">200</td>
              </tr>

              <tr>
                <td>washing couches</td>
                <td className="center">100</td>
              </tr>
              <tr>
                <td>washing lounge sets</td>
                <td className="center">150</td>
              </tr>
              <tr>
                <td>washing sofas</td>
                <td className="center">130</td>
              </tr>
              <tr>
                <td>washing the seats</td>
                <td className="center"> 50</td>
              </tr>
              <tr>
                <td>chair washing</td>
                <td className="center">10</td>
              </tr>
              <tr>
                <td>washing pillows</td>
                <td className="center"> 10</td>
              </tr>

              <tr>
                <td> mattress cleaning {<br />} ( 1 side ) </td>
                <td className="center"> 100</td>
              </tr>
              <tr>
                <td>washing baby car seats</td>
                <td className="center">100</td>
              </tr>
              <tr>
                <td>pram carrycot washing</td>
                <td className="center">100</td>
              </tr>
              <tr>
                <td>washing stuffed animals</td>
                <td className="center"> 20</td>
              </tr>
              <tr>
                <td colSpan="2" className="priceOne">
                  CARPET CLEANING PRICE LIST:
                </td>
              </tr>

              <tr>
                <td>carpet cleaning (artificial)</td>
                <td className="center">15 zł/m2</td>
              </tr>
              <tr>
                <td>carpet cleaning (wool)</td>
                <td className="center"> 20 zł/m2</td>
              </tr>
              <tr>
                <td>home carpet cleaning</td>
                <td className="center"> 10 zł/m2</td>
              </tr>

              <tr>
                <td>home carpet cleaning</td>
                <td className="center font">
                  price negotiable depending on size
                </td>
              </tr>
              <tr>
                <td>carpet cleaning in kindergartens</td>
                <td className="center font">
                  price negotiable depending on size
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="center font-upper">
                  {
                    'Delivery within Warsaw and surrounding areas (+50km) - FREE {for orders over PLN 150}'
                  }
                </td>
              </tr>
            </tbody>
          </table>

          <p className="lastquestion">
            <span>
              Please note that prices quoted are indicative only and may vary
              depending on each customer's individual needs and circumstances.
              We strive to provide our clients with the best services, which is
              why we adjust the price to specific requirements and
              circumstances. We value individual approach and diligence in
              meeting the needs of our clients. Thank you for understanding!
            </span>
          </p>
        </div>
      </div>
    </>
  );
}

function PriceRu() {
  return (
    <div className="body_wrapper">
      <Header />
      <ButtomsLang />
      <WhatsApp />
      <PriceTable />
      <Footer />
    </div>
  );
}
export default PriceRu;
