import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Articles.css';
import quilon from '../../../img/articles/article2.jpg';
import quilon2 from '../../../img/articles/article2.2.jpg';
import { Cataloge } from './1_Article';
import WhatsApp from '../../../WhatsUp/WhatsappLink';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>EN</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang && <Link to="/article1">PL</Link>}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

function Quilon() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Mattress washing Warsaw</h2>

            <p>
              The mattress is one of the most important elements of our bedroom
              and at the same time the place where we spend about one third of
              our lives. Therefore, it is important that our mattress is clean
              and hygienic. Regularly washing your mattresses is crucial to
              maintaining cleanliness and hygiene, and in this article we will
              present some reasons why it is so important.
            </p>
            <p>
              Firstly, washing mattresses removes contaminants such as dust,
              mites, molds, bacteria and viruses that accumulate in the mattress
              over time. These pollutants can cause allergies, asthma and other
              diseases, and also affect the quality of sleep. Therefore, it is
              important to wash your mattress regularly to remove these harmful
              contaminants.
            </p>
            <img className="quilon" src={quilon} title="quilon" alt="quilon" />
            <p>
              Secondly, washing mattresses affects the aesthetics and
              functionality of our mattress. A clean mattress looks more
              aesthetic and contributes to better well-being in the bedroom. In
              addition, washing mattresses can also improve the comfort of our
              sleep by removing stains, odors and other dirt that may affect the
              quality of sleep.
            </p>
            <p>
              Third, washing your mattress can extend its life. Mattresses are
              expensive investments, so it is important to take care of their
              durability. Regular washing can help keep your mattress clean and
              hygienic, as well as prevent wear and tear. Thanks to this, we
              will be able to enjoy our mattress for a longer time.
            </p>
            <img
              className="quilon2"
              src={quilon2}
              title="quilon"
              alt="quilon"
            />
            <p>
              Fourth, washing your mattresses can help combat unpleasant odors.
              Mattresses often accumulate odors from sweat, food, and other
              sources that can be difficult to remove. Washing mattresses
              removes these odors, which positively affects sleep comfort and
              air quality in our bedroom.
            </p>
            <p>
              To sum up, washing mattresses is extremely important and should be
              carried out regularly. By washing regularly, we ensure the
              cleanliness and hygiene of our mattress, improve sleep comfort,
              extend the life of our mattress and prevent the development of
              harmful pollutants. Washing mattresses is a simple and effective
              solution that will allow us to enjoy our mattress for a long time.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}

function Article2Ru() {
  return (
    <div className="body_wrapper">
      <Header />
      <ButtomsLang />
      <Quilon />
      <WhatsApp />
      <Footer />
    </div>
  );
}
export default Article2Ru;
