import Nav from '../Nav/Nav';
import { useState, useLayoutEffect } from 'react';
import { useProject } from '../../../tools/ProviderContext';
// import Logo from '../Logo/Logo';
import './Header.css';
import LinkPhone, { PhoneIcons } from '../LinkPhone/LinkPone';
import Schedule from '../Schedule/Schedule';
import LinkSocial from '../SocialLinks/LinkSocial';
import Logo from '../Logo/Logo';
import BurgerBtnMenu from '../BurgerBtnMenu/BurgerBtnMenu';

function AddHeader() {
  const { setIsScroll } = useProject();
  const [isHeader, setIsHeader] = useState(false);
  const [isScrollUp, setScrollUp] = useState(false);
  useLayoutEffect(() => {
    if (window.innerWidth < 490) {
      setIsHeader(true);
    } else {
      setIsHeader(false);
    }
  }, [setIsHeader]);

  window.addEventListener('scroll', function () {
    let scrolled = window.scrollY;
    if (scrolled > 50) {
      setScrollUp(true);
      setIsScroll(true);
    }
    if (scrolled < 50) {
      setScrollUp(false);
      setIsScroll(false);
    }
  });

  return (
    <>
      {!isScrollUp ? (
        <div className={'header__container-links'}>
          <div
            className={
              !isScrollUp
                ? 'container header__links'
                : 'container header__links-scroll'
            }
          >
            {isHeader ? (
              <>
                <Schedule />
                <div className="isHeaderPhoneSchedule">
                  <LinkSocial />
                  <PhoneIcons />
                </div>
              </>
            ) : (
              <>
                <Schedule />
                <LinkPhone />
                <LinkSocial />
              </>
            )}
          </div>
        </div>
      ) : (
        <div className={'header__fixed'}>
          <div
            className={
              !isScrollUp
                ? 'container header__links'
                : 'container header__links-scroll'
            }
          >
            {isHeader ? (
              <>
                <PhoneIcons />
                <LinkSocial />
              </>
            ) : (
              <>
                <Schedule />
                <LinkPhone />
                <LinkSocial />
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

function Header() {
  const { isMenu, closeMenu, closeBurgerMenu } = useProject();

  const { isBurgerMenu } = useProject();

  return (
    <>
      <header className={isBurgerMenu ? 'header open' : 'header'}>
        <AddHeader />
        <div className="header__menu">
          <div className="container header__container">
            <Logo />
            <div className="gratis2">
              <p> Access within Warsaw and</p>
              <p>surrounding areas (+50km) - FREE</p>
            </div>
            <BurgerBtnMenu />
            <Nav />
          </div>
        </div>
        <div
          onClick={() => {
            closeMenu();
            closeBurgerMenu();
          }}
          className={isMenu ? 'backdropMenu' : 'hidden'}
        ></div>
      </header>
    </>
  );
}
export default Header;
