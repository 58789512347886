import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import foto1 from '../../img/articles/article1.jpg';
import foto2 from '../../img/articles/article2.jpg';
import foto3 from '../../img/articles/article3.jpg';
import './General_img.css';

function Block() {
  return (
    <div className="block">
      <h3>Pranie mebli tapicerowanych</h3>
    </div>
  );
}
function BlockOneImgP() {
  return (
    <div>
      <img src={foto1} alt="foto1" className="fotoImg" />
      <p className="special">
        Czyszczenie mebli tapicerowanych to jeden z najważniejszych zabiegów
        higienicznych w naszym domu i pracy. Meble tapicerowane to nie tylko
        miejsce wypoczynku, ale często także miejsce pracy. jako powierzchnia do
        jedzenia. Gromadzą zatem mnóstwo brudu, bakterii i roztoczy, które mogą
        mieć negatywny wpływ na nasze zdrowie...
      </p>
    </div>
  );
}
function BlockOneCheck() {
  return (
    <div className="check">
      <Link to="/article3">Szczegółowo...</Link>
    </div>
  );
}
function BlockOneFromArticle1() {
  return (
    <div className="block_1">
      <Block />
      <BlockOneImgP />
      <BlockOneCheck />
    </div>
  );
}
function BlockTwo() {
  return (
    <div className="block">
      <h3>Pranie materacy</h3>
    </div>
  );
}
function BlockTwoImgP() {
  return (
    <div>
      <img src={foto2} alt="foto9" className="fotoImg" />
      <p className="special">
        Materac jest jednym z najważniejszych elementów naszej sypialni, a
        zarazem miejscem, w którym spędzamy około jednej trzeciej naszego życia.
        Dlatego ważne jest, aby nasz materac był czysty i higieniczny. Regularne
        pranie materacy jest kluczowe dla utrzymania czystości i higieny, a w
        tym artykule przedstawimy kilka powodów, dla których jest to tak
        istotne...
      </p>
    </div>
  );
}
function BlockTwoCheck() {
  return (
    <div className="check">
      <Link to="/article1">Szczegółowo...</Link>
    </div>
  );
}
function BlockTwoFromArticle2() {
  return (
    <div className="block_2">
      <BlockTwo />
      <BlockTwoImgP />
      <BlockTwoCheck />
    </div>
  );
}
function Block3() {
  return (
    <div className="block">
      <h3>Pranie dywanów i wykładzin</h3>
    </div>
  );
}
function BlockThreeImgP() {
  return (
    <div>
      <img src={foto3} alt="foto1" className="fotoImg" />
      <p className="special">
        Pranie dywanów i wykładzin to ważna czynność, która wpływa na czystość i
        higienę naszych domów. Pomimo regularnego odkurzania, na powierzchniach
        tych mogą gromadzić się różnego rodzaju zanieczyszczenia, które wpływają
        nie tylko na estetykę, ale również na nasze zdrowie. W tym artykule
        przedstawimy kilka powodów, dla których pranie dywanów i wykładzin jest
        tak istotne....
      </p>
    </div>
  );
}
function BlockThreeCheck() {
  return (
    <div className="check">
      <Link to="/article2">Szczegółowo...</Link>
    </div>
  );
}
function BlockOneFromArticle3() {
  return (
    <div className="block_3">
      <Block3 />
      <BlockThreeImgP />
      <BlockThreeCheck />
    </div>
  );
}
function SwiperArticles() {
  return (
    <Swiper
      navigation
      effect={'coverflow'}
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={'auto'}
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }}
      pagination={{ type: 'fraction' }}
      modules={[EffectCoverflow, Pagination, Navigation]}
      className="mySwiper"
    >
      <SwiperSlide>
        <BlockOneFromArticle1 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockTwoFromArticle2 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockOneFromArticle3 />
      </SwiperSlide>

      {/* <SwiperSlide></SwiperSlide> */}
    </Swiper>
  );
}
export default SwiperArticles;
