import './Important.css';
import expert from '../../../img/important/expert.png';
import quality from '../../../img/important/quality-control.png';
import warning from '../../../img/important/hand.png';

function Important() {
  return (
    <div className="main__important">
      <h2 className="main-important-h2">
        <span>Why us!!</span>
      </h2>

      <div className="main-important-blocks">
        <div className="main-important-block">
          <img src={expert} alt="expert" className="expert" />
          <h3>Professionalism</h3>
          <p>Our highly qualified specialists guarantee perfect results</p>
        </div>
        <div className="main-important-block">
          <img src={quality} alt="expert" className="expert" />
          <h3>Quality</h3>
          <p>
            We use state-of-the-art equipment and proven methods to achieve
            maximum cleanliness
          </p>
        </div>
        <div className="main-important-block">
          <img src={warning} alt="expert" className="expert" />
          <h3>Individual approach</h3>
          <p>
            We take into account the individual needs and requirements of each
            client
          </p>
        </div>
      </div>
    </div>
  );
}
export default Important;
