import { Link } from 'react-router-dom';
import Img_Facebook from '../../../img/links/facebook2.png';
import Img_Instagram from '../../../img/links/instagram.png';

import './LinkSocial.css';

function LinkSocial() {
  return (
    <div className="header__list-social-media">
      <div className="header__social-link-item">
        <Link
          to="https://www.instagram.com/smell.cleanpl/?igsh=MXRieHFsbjBwbDFqeg%3D%3D&utm_source=qr"
          title="instagram"
          alt="instagram"
          target="_blank"
          className="header__instargam"
        >
          <img src={Img_Instagram} alt="instagram" />
        </Link>
      </div>
      <div className="header__social-link-item">
        <Link
          to="https://www.facebook.com/profile.php?id=61558754762175"
          target="_blank"
          alt="facebook"
          title="facebook"
          className="header_facebook"
        >
          <img src={Img_Facebook} alt="facebook" />
        </Link>
      </div>
    </div>
  );
}
export default LinkSocial;
