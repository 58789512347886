import './Services.css';
import main1 from '../../../img/Services/clean_sofa.jpg';
import main4 from '../../../img/Services/clean_matrac.jpg';
import main3 from '../../../img/Services/clean_pillow.jpg';
import main2 from '../../../img/Services/clean_chair.jpg';
import { Link } from 'react-router-dom';

function Services() {
  return (
    <>
      <div className="main__first-services">
        <div className="main-title main-services">
          <p>Nasze usługi</p>
          <p className="main-title-upper">
            <span>Dla firm i Dla domu</span>
          </p>
          <h1>
            Profesjonalnie Pranie kanap, foteli, materacy, wózków, zasłon,
            rolet, wykładzin, dywanów, wnętrz samochodu….
          </h1>
        </div>

        <div className="main__services">
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main1} alt="photo1" />
            </div>
            <h2>Pranie kanap</h2>
            <p className="main-services-price">od 150 zł</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main2} alt="photo1" />
            </div>
            <h2>Pranie foteli</h2>
            <p className="main-services-price">od 50 zł</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main3} alt="photo1" />
            </div>
            <h2>Pranie poduszek</h2>
            <p className="main-services-price">od 10 zł</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main4} alt="photo1" />
            </div>
            <h2>PRANIE MATERACY</h2>
            <p className="main-services-price">od 100 zł</p>
          </div>
        </div>
        <div className="main__link-details">
          <Link to="/price">Szczegółowo...</Link>
        </div>
      </div>
    </>
  );
}
export default Services;
