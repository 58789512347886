import { useLayoutEffect, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
// import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';
import foto1 from '../../../img/gallary/foto1.jpg';
import foto2 from '../../../img/gallary/foto2.jpg';
import foto3 from '../../../img/gallary/foto3.jpg';
import foto4 from '../../../img/gallary/foto4.jpg';
import foto5 from '../../../img/gallary/foto5.jpg';

import './General_img.css';

function BlockOneImgP() {
  return (
    <div>
      <img src={foto1} alt="foto1" className="fotoImg" />
    </div>
  );
}

function BlockOneFromArticle1() {
  return (
    <div className="galarry-block_1">
      <BlockOneImgP />
    </div>
  );
}

function BlockTwoImgP() {
  return <img src={foto2} alt="foto9" className="fotoImg fotoImg2" />;
}

function BlockTwoFromArticle2() {
  return (
    <div className="galarry-block_2">
      <BlockTwoImgP />
    </div>
  );
}

function BlockThreeImgP() {
  return (
    <div>
      <img src={foto3} alt="foto1" className="fotoImg" />
    </div>
  );
}

function BlockOneFromArticle3() {
  return (
    <div className="galarry-block_3">
      <BlockThreeImgP />
    </div>
  );
}
function Block4ImgP() {
  return (
    <div>
      <img src={foto4} alt="foto4" className="fotoImg" />
    </div>
  );
}

function BlockOneFromArticle4() {
  return (
    <div className="galarry-block_4">
      <Block4ImgP />
    </div>
  );
}
function Block5ImgP() {
  return (
    <div>
      <img src={foto5} alt="foto5" className="fotoImg" />
    </div>
  );
}

function BlockOneFromArticle5() {
  return (
    <div className="galarry-block_5">
      <Block5ImgP />
    </div>
  );
}
function SwiperArticles() {
  return (
    <Swiper
      spaceBetween={20}
      slidesPerView={2}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      navigation={true}
      modules={[Pagination, Navigation]}
      className="mySwiper"
    >
      <SwiperSlide>
        <BlockOneFromArticle4 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockOneFromArticle1 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockOneFromArticle5 />
      </SwiperSlide>

      <SwiperSlide>
        <BlockTwoFromArticle2 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockOneFromArticle3 />
      </SwiperSlide>
    </Swiper>
  );
}

function SwiperArticles2() {
  return (
    <Swiper
      spaceBetween={20}
      slidesPerView={1}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      navigation={true}
      modules={[Pagination, Navigation]}
      className="mySwiper"
    >
      <SwiperSlide>
        <BlockOneFromArticle4 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockOneFromArticle1 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockOneFromArticle5 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockTwoFromArticle2 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockOneFromArticle3 />
      </SwiperSlide>
    </Swiper>
  );
}

function TitleGallary() {
  const [isHeader, setIsHeader] = useState(false);

  useLayoutEffect(() => {
    if (window.innerWidth < 580) {
      setIsHeader(true);
    } else {
      setIsHeader(false);
    }
  }, [setIsHeader]);
  return (
    <>
      <div className="main__second-services main-second">
        <div className="main-title">
          <p>Our works</p>
          <h2>Washing car furniture, upholstery, carpets and rugs</h2>
        </div>
        {!isHeader ? <SwiperArticles /> : <SwiperArticles2 />}
      </div>
    </>
  );
}
export default TitleGallary;
