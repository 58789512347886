import React, { useState } from 'react';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import LinkSocial from '../../1_Header/SocialLinks/LinkSocial';
import foto from '../../../img/contact/insurance.jpg';
import './Contact.css';
import WhatsApp from '../../../WhatsUp/WhatsappLink';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="25px">
          <Button onClick={changeLang} select="select">
            <span>EN</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang ? (
              <Link to="/contact">PL</Link>
            ) : (
              <Link to="/contact">RU</Link>
            )}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

function ContactTable() {
  return (
    <div className="contact">
      <div className="contacts-title">
        <h1>Contact</h1>
      </div>
      <div className="container contacts__container">
        <div className="contacts__item">
          <div className="contacts__item-phone">
            <p>Phone number:</p>
            <Link to={'tel:+48576557446'}>
              <h2 className="header-phone-item">+48 576557446</h2>
            </Link>
          </div>
          <div className="contacts__item-phone">
            <p>Email:</p>
            <Link to={'mailto:smellcleanpl@gmail.com'}>
              <h2 className="header-phone-item">smellcleanpl@gmail.com</h2>
            </Link>
          </div>
          {/* <div className="contacts__item-whatsapp">
            <p className="contacts__item-whatsapp-text">WhatsApp:</p>
            <Link to="https://wa.me/48506778684">
              <img src={whatsapp} alt="WhatsApp" title="WhatsApp" />
            </Link>
          </div> */}

          <div className="contacts__item-social">
            <p>We are on social networks:</p>
            <div className="contacts__item-social-links">
              <LinkSocial />
            </div>
          </div>
          <div className="contacts__item-praca">
            <h2>Work plan</h2>
            <img src={foto} alt="foto" />
            <p>We work 7 days a week, from 6:00 a.m. to 10:00 p.m.,</p>
            <p>and if the situation requires it, also at night.</p>
            <p>We operate in Warsaw and the surrounding area (+100 km).</p>
            <p>
              We offer free transport within Warsaw and the surrounding area up
              to 50 km.
            </p>
          </div>
          <div className="adress">Warsaw, Poland</div>
        </div>
      </div>
    </div>
  );
}
function ContactRu() {
  return (
    <div className="body_wrapper">
      <Header />
      <ButtomsLang />
      <ContactTable />
      <WhatsApp />
      <Footer />
    </div>
  );
}
export default ContactRu;
